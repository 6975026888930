@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --primary-color: #0389c9;
  --bodytext: "roboto";
  --titletext: "Montserrat", sans-serif;
  --primary-light: #edf7fb;
  --gray-light: #f7f7f7;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
.overflow-hidden {
  overflow: hidden;
}
.mobile-fix-menu {
  z-index: 1099;
  background-color: #ffffff;
  box-shadow: 0 0 8px rgb(0 0 0 / 5%);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  display: none;
  /* z-index: 1999; */
}
.ellipse-box {
  min-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.fixed-desc-box {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.remove-bold {
  font-weight: 400 !important;
  font-family: var(--bodytext);
}
.display-grid {
  display: grid;
  grid-template-columns: minmax(160px, auto) repeat(
      auto-fit,
      minmax(620px, auto)
    );
}
.gap-60 {
  gap: 60px;
}
.gap-30 {
  gap: 30px;
}
.hide {
  display: none;
}
/* .des {
  display: block;
  text-overflow: ellipsis;
  word-wrap: inherit;
  overflow: hidden;
  max-height: 3em;
} */

.mw-315 {
  min-width: 315px;
}
.flex-direction {
  display: flex;
  flex-direction: column;
}
.flex-direction-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-direction-column {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: column;
}
.about-flex {
  display: flex;
}
.italic-bold {
  font-style: italic;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-weight: 600;
  color: #51595e;
  /* text-transform: capitalize; */
}
.MuiDataGrid-row:hover {
  background-color: rgb(237 247 251) !important;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

.flex-4 {
  flex: 4;
}
.flex {
  display: flex;
}

.about-list-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.profile-head {
  display: flex;
}

.landing-navbar {
  display: flex;
  gap: 40px;
}
.landing-navbar svg {
  font-size: 20px;
}
.landing-body-wrap {
  min-height: 72vh;
  width: 70vw;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 55px;
}
.landing-body-wrap .flex3 {
  flex: 1;
  /* gap: 10px; */
  display: flex;
  gap: 20px;
}
.landing-body-wrap .flex1 {
  height: 60px;
}

.landing-body-wrap .flex2 h2 {
  font-size: 28px;
  color: var(--primary-color);
}
.landing-body-wrap .flex2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 155px;
  min-height: 200px;
}
.landing-body-wrap .flex2 span {
  font-family: var(--bodytext);
  color: #676767;
}
.landing-search-bar fieldset {
  border: none;
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
}

.searchbar-icon {
  position: absolute;
  right: 1%;
  top: 50%;
  transform: translateY(-50%);
}
.searchbar-icon svg {
  font-size: 20px;
}

.form-wrap .form-group label {
  font-weight: 100;
  /* border: 1px solid #ced4da; */
  color: #a2acb8;
  font-size: 2.1vh;
}
.idea-form-upload-cv {
  display: flex;
  align-items: center;
  gap: 20px;
}
.idea-form-upload-cv > div {
  flex: 1;
}
.MuiOutlinedInput-notchedOutline {
  border-color: rgb(6 8 11 / 10%) !important;
}
.buttons {
  background-color: var(--primary-color);
  color: white;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* semi-transparent black */
  display: flex;
  align-items: last baseline;
  /* justify-content: center; */
}
.overlay span :is(h1) {
  display: flex;
  flex-direction: column;
  color: #fff;
  line-height: 50px;
}
.overlay span {
  padding: 20px 20px 50px 50px;
}
.overlay p {
  font-size: 1.5rem;
}
.dropdown-text {
  font-size: 0.9rem;
}
.MuiTableCell-root {
  border-bottom: none !important;
}
.MuiTable-root th {
  padding: 0px !important;
}
.nav-searchicon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(121 124 126);
}
.search-box {
  position: relative;
}
.fixed-width-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.fixed-width-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 11rem;
}
/* .flex-direction {
  flex-direction: column;
  gap: 10px;
} */
#center-content {
  width: 100% !important;
}
.MuiButtonBase-root:hover {
  background-color: rgb(242 249 252) !important;
}
.hrClass {
  color: #dedede !important;
}
.hrClass2 {
  color: #454545 !important;
  margin: 10px 0 !important;
}
.avatar-circle {
  border: 1px solid #c8c8c8;
  padding: 5px;
}
.btn-filled {
  background-color: var(--primary-color) !important;
  border-radius: 5px;
  border: 1px solid var(--primary-color) !important;
  color: #ffffff !important;
  transition: all 0.5s ease;
  min-width: 110px;
}

.btn-filled:hover {
  color: var(--primary-color) !important;
  background-color: #ffffff !important;
  transition: all 0.5s ease;
  box-shadow: 0px 9px 30px 0px rgb(3 137 201 / 22%);
}

.btn-outlined {
  color: var(--primary-color);
  border: 1px solid var(--primary-color) !important;
  background-color: #ffffff;
  transition: all 0.5s ease;
  border-radius: 5px;
  min-width: 110px;
}

.btn-outlined:hover {
  background-color: var(--primary-color);
  color: #ffffff !important;
  border: 1px solid #0389c9;
  transition: all 0.5s ease;
  box-shadow: 0px 9px 30px 0px rgb(3 137 201 / 22%);
}
.MuiBox-root {
  border-bottom: none !important;
}
.MuiTabPanel-root {
  padding: 20px 0 !important;
}
.MuiTab-root {
  color: var(--primary-color) !important;
}
.m-d {
  color: var(--primary-color) !important;
  /* font-weight: 500; */
}
.p-0 {
  padding: 0;
}
.p-10-0 {
  padding: 10px 0px 0px 0px !important;
}
.MuiButtonBase-root:hover {
  color: var(--primary-color) !important;
}
.MuiTab-root {
  font-family: var(--titletext) !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent {
  font-family: var(--titletext) !important;
  font-weight: bolder !important;
}
.active {
  background-color: #00acff30;
  border-radius: 10px;
}
.title-main span {
  color: var(--primary-color);
}
.tab-title {
  font-weight: bold;
  font-size: 1.1rem;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-gap-max {
  display: flex;
  gap: 60px;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.m-top-bottom {
  margin: 0px 0px 30px 0;
}
.m-top-2 {
  margin-top: 2px;
}
.m-top {
  margin-top: 10px !important;
}
.remove-bg-and-box-shadow {
  box-shadow: unset !important;
  background-color: unset !important;
}
.m-top-0 {
  margin-top: 0 !important;
}
.m-bottom-5 {
  margin-bottom: 5px;
}
.m-bottom-10 {
  margin-bottom: 10px !important;
}
.m-bottom-0 {
  margin-bottom: 0px !important;
}
.gap-sm {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}

.earth {
  display: flex;
  margin-top: 2px;
}
.bottom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 5px 10px; */
}
.label {
  border-bottom: 1px solid rgba(3, 137, 201, 0.2);
  margin-block: 15px;
}
.tooltip-cls {
  font-size: 0.7rem;
}

.reacts {
  display: flex;
  align-items: center;
  gap: 20px;
}
.reacts-box {
  display: flex;
  align-items: center;
  gap: 1px;
}
.reacts-box span {
  font-size: 0.8rem;
  font-weight: bold;
}

.nav-list {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 5px;
}
.nav-text {
  font-size: 14px;
  font-weight: bold;
}
.icon-svg {
  font-size: 1.2rem;
  color: #3f3c3c;
}
.search-input {
  padding: 10px;
  background-color: #f2f9fc;
}
.small-search-box {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  /* box-shadow: 0px 1px 3px #3dc0ff4f; */
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  transition: display 0.5s ease-in-out;
}
.searchbar-wrap {
  display: none;
}

.small-search-box input {
  height: 10px;
  /* border-color: #cccccc; */
  /* background-color: #f2f9fc; */
  border: none;
}
.option-list {
  gap: 25px;
}
.modal-cross {
  float: right;
  padding: 0 !important;
}
.modal-cross svg {
  font-size: 1rem;
  cursor: pointer;
}
.list-fix-width {
  min-width: 35%;
  width: 40%;
}
.MuiIconButton-root:hover {
  border-radius: 5px !important;
}
header .header-section {
  gap: 20px;
}
header .header-section .header-right {
  margin-left: 0px !important;
}
header .header-section .header-left {
  margin-left: auto;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;
}
header .header-section .header-left .search-box {
  width: 100%;
}
header.header-light .header-section .header-left .search-box .form-control {
  width: 100%;
}
.option-list {
  gap: 60px !important;
}
.svg-font {
  font-size: 1.6rem;
}
.sidebar-panel .sidebar-icon > li + li a {
  margin-top: 20px !important;
}
.post-panel .post-wrapper .post-details .detail-box {
  padding: 0 35px 15px 35px !important;
}
.flex-g-5 {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-family: var(--bodytext);
  font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
}

.text-right {
  text-align: right;
}

.idea-location {
  display: flex;
  align-items: center;
  gap: 2px;
  /* justify-content: space-between; */
  min-width: 130px;
  font-family: var(--bodytext);
  font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
}

.flex-g-10 {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-family: var(--bodytext);
  font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
}
.likes-section {
  padding: 0 35px 15px 35px !important;
}
.svg-size svg {
  font-size: 1.2rem !important;
}
.post-panel .post-wrapper .post-details .img-wrapper {
  margin-bottom: 15px !important;
}
.media h5 {
  font-family: "Montserrat", sans-serif;
  color: #51595e;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: calc(14px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
}
.media h6 {
  text-transform: capitalize;
  color: #647589;
  font-weight: 500;
}
.idea-title-detail .media {
  display: flex;
  flex-direction: column;
}
.idea-title .media {
  display: flex;
  flex-direction: column;
}
.idea-title {
  display: flex;
  align-items: center;
  padding: 25px 35px 0px 35px;
  justify-content: space-between;
}
.idea-title-detail {
  display: flex;
  align-items: center;
  padding: 0px 35px 0px 35px;
  justify-content: space-between;
}
.follow-button-all .MuiButtonBase-root {
  color: var(--primary-color) !important;
  text-transform: capitalize;
}
.post-panel .post-wrapper {
  margin-bottom: 10px;
}
.bottom-section {
  padding: 0 35px 25px 35px;
}
.modal-text h5 {
  font-size: 1rem;
  font-weight: bold;
}
.modal-text h6 {
  font-size: 0.8rem;
  margin: 10px 0px 30px 0px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.flex-gap-max ul.about-list li .title {
  width: 100% !important;
}
.status-ongoing {
  color: #e9af27 !important;
  border: 1px solid #e9af27;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  border-radius: 5px;
}
.feed-detail-list {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  width: 100%;
  margin-top: 8px;
}
.feed-detail-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.stack-item li {
  display: flex;
  align-items: center;
  /* gap: 50px; */
  justify-content: space-between;
  padding: 10px;
}

.feed-detail-list li:nth-child(even) {
  background-color: var(--gray-light);
}

.feed-detail-list h6 {
  font-size: 0.86rem;
  display: flex;
  align-items: center;
  gap: 5px;
}
.feed-detail-list h6 span {
  font-family: var(--bodytext);
  display: flex;
  align-items: center;
  gap: 10px;
}
.feed-detail-list h5 svg {
  color: #00000080;
  /* color: #f15642; */
}

.feed-detail-list h5 {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}
.uploaded-files li {
  display: flex !important;
  justify-content: space-between !important;
}

.cards-main {
  height: auto;
}

.desktop-menu {
  display: block;
}

.mobile-menu {
  display: none;
}

.tooltip-cls {
  left: unset !important;
  right: 65px;
}

.tooltip-cls:after {
  display: none;
}

.suggestion-display2 {
  display: block;
}
.suggestion-box-display1 {
  display: none;
}
.about-profile {
  box-shadow: unset !important;
}
.flex-direction {
  flex-direction: unset;
  gap: unset;
}
.hrFeed {
  margin-top: 0px;
}
.seperate-line li h5 {
  align-items: left !important;
}
.italic {
  font-style: italic;
  font-size: 0.7rem;
  color: #a3a3a3;
}
.view-svg {
  padding: 8px;
}
.svg-size {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.54);
}
.w-100 {
  width: 100%;
}
.light-color {
  color: rgba(100, 117, 137, 0.7) !important;
  font-weight: 100 !important;
  font-size: 0.75rem;
}
.custom-top {
  position: relative;
  top: -80px;
}
.landing-card-wrapper {
  padding: 80px 30px 10px 30px;
  display: flex;
}
/* .new-content-left {
  width: 489px !important;
} */
/* .page-content .content-center:not(.width-abjust) {
  width: calc(100% - 380px) !important;
} */
/*  */
@media only screen and (max-width: 1367px) and (min-width: 1199px) {
  #center-content {
    width: calc(100% - 300px);
  }
  .landing-body-wrap {
    width: 80vw;
  }
}

/* @media only screen and (max-width: 1600px) and (min-width: 1199px) {
  .cards-main {
    max-height: 185px;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1199px) {
  #center-content {
    width: calc(100% - 300px);
  }
  .landing-body-wrap {
    width: 90%;
  }
  #center-content {
    margin-bottom: 20px;
  }
  .custom-top {
    position: relative;
    top: unset !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .about-flex {
    flex-direction: column;
  }
  .profile-head {
    justify-content: center;
    align-items: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  .idea-title {
    padding: 5px 15px;
  }
  .flex3 {
    display: flex;
    flex-direction: column;
  }
  .cards-main,
  .card-content {
    width: 100%;
  }

  header .header-section,
  header .header-section .header-left {
    gap: 10px;
  }

  .option-list {
    gap: 20px !important;
  }

  .landing-body-wrap {
    width: 95%;
    padding: 35px;
  }
  .landing-body-wrap .flex3 {
    gap: 15px;
  }
  .card-content {
    gap: 30px;
    padding: 15px;
  }
  .MuiIconButton-root {
    border-radius: 0px !important;
  }
  .idea-form {
    padding: 15px !important;
  }
  .post-wrapper {
    padding: 15px !important;
  }
}

/* Extra small devices (phones, 600px and down) */

@media (max-width: 576px) {
  .idea-title-detail {
    display: flex;
    align-items: center;
    padding: 0px;
    justify-content: space-between;
  }
  .likes-section {
    margin-bottom: 5px;
  }
  .mobile-fix-menu {
    display: block;
  }
  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
  .profile-box {
    display: none;
  }
  .suggestion-box-display1 {
    display: block;
    margin-bottom: 10px;
    width: 100vw;
  }
  .suggestion-display2 {
    display: none;
  }
  .flex-direction {
    flex-direction: column;
    gap: 10px;
  }

  /* .page-body.custom-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  } */
  /* .profile-box .profile-content .image-section .profile-img:after {
    width: 80px !important;
    height: 80px !important;
  } */
}

@media only screen and (max-width: 428px) {
  .overlay span {
    padding: 20px 20px 20px 20px;
  }
  .overlay p {
    font-size: 0.9rem;
  }
  .landing-body-wrap {
    padding: 15px;
  }
  .cards-main {
    width: 100%;
  }
  .search-box {
    /* display: none; */
    width: 100vw;
    padding: 15px;
    background-color: white;
  }
  /* .searchbar-wrap {
    display: inline-block;
  } */
  .f-direction {
    display: flex;
    flex-direction: column;
  }
  .MuiIconButton-root {
    border-radius: 0px !important;
  }
  .box-gap {
    flex-direction: column;
  }
  .reacts {
    gap: 25px;
  }
  .reacts-box {
    gap: 1px;
  }
  .MuiTab-root {
    min-width: 62px !important;
    font-size: 0.7rem !important;
  }
  .option-list {
    gap: 10px;
  }
  .hrFeed {
    margin-top: 10px !important;
  }

  header .header-section .header-left {
    gap: 5px;
    margin-left: auto;
  }
  .post-panel .post-wrapper .post-details .detail-box {
    padding: 0 !important;
  }
  .idea-title {
    padding: 0;
  }
  .likes-section {
    padding: 0 0px 5px 0px !important;
  }
  .bottom-section {
    padding: 0px;
  }
  .post-panel .post-wrapper .post-details .detail-box .tag {
    margin-bottom: 10px;
  }
  .page-body {
    padding-top: 60px;
  }
  .modal-cross svg {
    font-size: 1.2rem;
  }
  .seperate-line li h5 {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .bottom-section {
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }
  .landing-card-wrapper {
    padding: 80px 15px 10px 15px;
  }
}

@media only screen and (max-width: 380px) {
  .about-list-wrapper {
    flex-direction: column;
  }
}

/* Extra CSS */
.my-div {
  height: initial;
}

@media (max-width: 992px) {
  .my-div {
    height: fit-content !important;
  }
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

/* @media (max-width: 1367px) and (min-width: 1199px) {
  .layout-default .page-content .content-left {
    width: 340px !important;
  }
}

@media (max-width: 1367px) and (min-width: 1199px) {
  .page-content .content-left {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.page-content .content-left {
  margin-bottom: 30px;
} */
